import React, { useEffect, useRef } from "react";
import Footer from "../footer/footer";
import Navbar from "../main/navbar";
import Topbar from "../main/topbar";
import { LazyLoadComponent } from "../../helpers/lazyLoadComponents";

export default function Main({ component: Component, ...rest }) {
  const header = useRef(null);
  const content = useRef(null);
  const handleScroll = () => {
    if (window.scrollY < 43) {
      // setactiveClass('');
      header.current.classList.remove("fixed");
      content.current.classList.remove("fixed");
    } else if (window.scrollY > 44) {
      // setactiveClass('fixed');
      header.current.classList.add("fixed");
      content.current.classList.add("fixed");
    }
  };
  useEffect(() => {
    (async () => {
      window.addEventListener("scroll", handleScroll);
    })();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className="main-header" ref={header}>
        <div className="main-header-block">
          <Topbar />
          <Navbar />
        </div>
        <div className="main-header-background"></div>
      </header>
      <div className={`content-wrapper`} ref={content}>
        <div className="app-content">
          <Component />
        </div>
        <LazyLoadComponent component={<Footer />} />
      </div>
    </>
  );
  // }
}
