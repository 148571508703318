import React, { useState, Suspense, useEffect, lazy } from "react";
// import { Bredcrumbs } from './components/bredcrumbs.jsx';
//Routing
import { Router, Route, Switch } from "react-router-dom";
// fontwasome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faPhoneAlt,
  faClock,
  faChevronRight,
  faChevronLeft,
  faUserFriends,
  faChartArea,
  faProjectDiagram,
  faPhoneVolume,
  faCaretRight,
  faEnvelope,
  faTasks,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

// Helpers
import history from "./helpers/history.jsx";
import { useStateWithLocalStorage } from "./helpers/storage.jsx";
import { GlobalContext } from "./helpers/globalContext.jsx";
import * as API from "./helpers/api.jsx";
import config from "./config";
import {
  cookies,
  setCookieToken,
  clearAllCookies,
} from "./helpers/cookies.jsx";
import Loading from "./helpers/loading.jsx";

import Main from "./components/main/main";
import Header from "./components/header/header.jsx";
import { LazyLoadComponent } from "./helpers/lazyLoadComponents";
const Footer = lazy(() => import("./components/footer/footer.jsx"));

// Components
const AboutUs = React.lazy(() => import("./components/about-us/aboutUs"));
const Categories = React.lazy(() =>
  import("./components/categories/categories")
);
const Services = React.lazy(() => import("./components/services/services"));
const Service = React.lazy(() => import("./components/services/service"));
const TeamList = React.lazy(() => import("./components/team/team-list"));
const Team = React.lazy(() => import("./components/team/team"));
const Patients = React.lazy(() => import("./components/patients/patients"));
const Patient = React.lazy(() => import("./components/patients/patient"));
const Videos = React.lazy(() => import("./components/videos/videos"));
const Video = React.lazy(() => import("./components/videos/video"));
const Contacts = React.lazy(() => import("./components/contacts/contacts"));
const Page404 = React.lazy(() => import("./components/page404"));

// global components
const Home = React.lazy(() => import("./components/home/home"));
const Mission = React.lazy(() => import("./components/mission/mission"));
const History = React.lazy(() => import("./components/history/history"));
const License = React.lazy(() => import("./components/license/license"));
const Gallery = React.lazy(() => import("./components/gallery/gallery"));
const Privacy = React.lazy(() => import("./components/terms/terms"));
const Terms = React.lazy(() => import("./components/privacy/privacy"));

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faClock,
  faPhoneAlt,
  faFacebookF,
  faInstagram,
  faChevronRight,
  faChevronLeft,
  faUserFriends,
  faChartArea,
  faProjectDiagram,
  faPhoneVolume,
  faCaretRight,
  faWhatsapp,
  faEnvelope,
  faViber,
  faTasks,
  faBars,
  faTimes
);

// function ScrollToTop() {
// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	return null;
// }

function RouterComponent() {
  // window.scrollTo(0, 0);
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/category" component={Categories} />
      <Route exact path="/category/:id" component={Services} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/services/:id" component={Service} />
      <Route exact path="/team" component={TeamList} />
      <Route exact path="/team/:id" component={Team} />
      <Route exact path="/patients" component={Patients} />
      <Route exact path="/patients/:id" component={Patient} />
      <Route exact path="/video" component={Videos} />
      <Route exact path="/video/:id" component={Video} />
      <Route exact path="/contacts" component={Contacts} />
      <Route exact path="/mission" component={Mission} />
      <Route exact path="/history" component={History} />
      <Route exact path="/license" component={License} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route path="*" component={Page404} status={404} />
    </Switch>
  );
}

function App() {
  let [lang, setLang] = useStateWithLocalStorage("language");
  let [cart, setCart] = useStateWithLocalStorage("cart", "array");
  let [cartData, setCartData] = useState(undefined);
  let [loggedIn, setLoggedIn] = useState(Boolean);
  let [keyFile, setKeyFile] = useState(Boolean);
  let [user, setUser] = useState({});
  let [open, setOpen] = useState(false);
  let [bredCrumbsCategory, setbredCrumbsCategory] = useState(false);
  let [modalFor, setModalFor] = useState("");
  let url = new URL(window.location.href);
  let restParams = {
    lang: url.searchParams.get("lang"),
  };
  const [adult, setAdult] = useState(undefined);

  async function logout() {
    setCookieToken("");
    setLoggedIn(false);
    setUser({});
  }
  const openModal = (data, state, unused, url) => {
    document.body.classList.add("show-modal");
    data && window.history.pushState(data?.href, "", `/product/${data?.href}`);
    setModalFor(data);
    setOpen(true);
  };
  const closeModal = (goBack) => {
    document.body.classList.remove("show-modal");
    goBack && window.history.go(-1);
    setOpen(false);
  };
  const modalToggle = () => {
    if (!open) document.body.classList.add("show-modal");
    else document.body.classList.remove("show-modal");
    setOpen(!open);
  };
  function checkToken() {
    let cookieToken = cookies.get(config.authentification.cookieToken);
    if (!!cookieToken && cookieToken !== "undefined") {
      API.post(config.authentification.verifyToken, {
        token: cookieToken,
      })
        .then((res) => {
          if (res) {
            if (res.status === "success" || res.status === 200) {
              setLoggedIn(true);
            }
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          clearAllCookies();
          setUser();
          setLoggedIn(false);
          // window.location.reload(false); //? reload webpage
        });
    } else {
      API.post(config.authentification.verifyKeyFile, {
        keyFile: config.authentification.key,
      })
        .then((res) => {
          if (res.status === "success" || res.status === 200) {
            setKeyFile(true);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          clearAllCookies();
          setKeyFile(false);
          // window.location.reload(false); //? reload keyfile
        });
    }
  }

  useEffect(() => {
    if (restParams.lang) {
      if (restParams.lang === "ru") setLang("ru");
      else if (restParams.lang === "hy") setLang("hy");
      else setLang("hy");
    } else if (lang) {
      if (lang === "ru") setLang("ru");
      else if (lang === "hy") setLang("hy");
      else setLang("hy");
    } else {
      setLang("hy");
    }

    (async () => {
      await checkToken();
    })();
    // eslint-disable-next-line
  }, []);

  if (loggedIn === true || keyFile === true) {
    return (
      <GlobalContext.Provider
        value={{
          logout,
          loggedIn,
          user,
          cart,
          setCart,
          cartData,
          setCartData,
          lang,
          setLang,
          adult,
          setAdult,
          // bredCrumbsCategory,
          // setbredCrumbsCategory,
        }}
      >
        <Router history={history}>
          <Switch>
            <Suspense fallback={<Loading className="loader" />}>
              <Main component={RouterComponent} />
            </Suspense>
          </Switch>
        </Router>
      </GlobalContext.Provider>
    );
  } else {
    return <Loading className="loader" />;
  }
}

export default App;
