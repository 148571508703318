const config = {
  api: {
    contacts: "contacts",
    services: "services",
    category: "category",
    clients: "clients",
    history: "history",
    mission: "mission",
    privacy: "privacy",
    terms: "terms",
    license: "license",
    team: "team",
    positions: "positions",
    patients: "patients",
    video: "video",
    upload: "upload",
    API_URL: process.env.REACT_APP_API_URL,
    API_Version: "v4",
  },
  authentification: {
    loginKeyFile: "/login/client-keyFile",
    loginToken: "/login/client-token",
    verifyKeyFile: "/verify/client-keyFile",
    verifyToken: "/verify/client-token",
    cookieKeyFile: "keyFile",
    cookieToken: "client-token",
    verifySMS: "verify-SMS",
    registerClient: "register-client",
    registerOrder: "register-order",
    updateClient: "update-client",
    key: process.env.REACT_APP_API_KEY,
  },
  contacts: {
    tel: ["+374 10 529006", "+374 96 529006", "+374 94 529006"],
    whatsapp: "+374 94 529006",
    viber: "+374 94 529006",
    facebook: "https://www.facebook.com/FanarjyanClinic/",
    instagram: "https://instagram.com/fanarjyanclinic?igshid=qon2qw1197gh",
    youtube:
      "https://www.youtube.com/channel/UCAQKrAAm-Fos-eF2D-Bw-Lg?view_as=subscriber",
    email: "fanarjyanclinic@gmail.com",
    address: {
      hy: "Սարմենի 90, 0019 Երևան",
      ru: "Сармена 90, 0019 Ереван",
      en: "Sarmen 90, 0019 Erevan",
    },
  },
  lang: {
    topbar: {
      workingDays: {
        hy: "Երկուշաբթի - Շաբաթ",
        ru: "Понедельник - Суббота",
        en: "Monday - Saturday",
      },
      workingHours: "09:00 - 18:00",
    },
    menu: {
      home: {
        hy: "Հիմնական",
        ru: "Главная",
        en: "Home",
      },
      aboutUs: {
        hy: "Մեր մասին",
        ru: "О нас",
        en: "About us",
      },
      gallery: {
        hy: "Նկարներ",
        ru: "Фото",
        en: "Photo",
      },
      videos: {
        hy: "Տեսանյութեր",
        ru: "Видео",
        en: "videos",
      },
      video: {
        hy: "Տեսանյութեր",
        ru: "Видео",
        en: "Video",
      },
      categories: {
        hy: "Կատեգորիաներ",
        ru: "Услуги",
        en: "Services",
      },
      terms: {
        en: "Terms",
        ru: "Условия",
        hy: "Պայմաններ",
      },
      privacy: {
        en: "Privacy policy",
        ru: "Политика конфиденциальности",
        hy: "Գաղտնիության համաձայնագիր",
      },
      services: {
        hy: "Ծառայություններ",
        ru: "Услуги",
        en: "Services",
      },
      team: {
        hy: "Մեր բժիշկները",
        ru: "Наши врачи",
        en: "Our doctors",
      },
      license: {
        hy: "Վերապատրաստումներ",
        ru: "Повышение квалификации",
        en: "Trainings",
      },
      patients: {
        hy: "Հոդվածներ",
        ru: "Статьи",
        en: "For patients",
      },
      contacts: {
        hy: "Կոնտակտներ",
        ru: "Контакты",
        en: "Contacts",
      },
      news: {
        hy: "Նորություններ",
        ru: "Новости",
        en: "News",
      },
    },
    goToHomePage: {
      hy: "Գլխավոր էջ",
      ru: "Главная",
      en: "Home",
    },
    callUs: {
      ru: "Позвоните нам",
      hy: "Զանգահարեք մեզ",
      en: "Call us",
    },
    homePage: {
      slogan: {
        hy: "Ֆանարջյան ՓԲԸ",
        ru: "ЗАО «Волиос»",
        en: "fanarjyan CJSC",
      },
      subSlogan: {
        hy: "Կարգապահություն, որակ, առաջընթաց",
        ru: "Дисциплина, качество, прогресс",
        en: "Discipline, quality, progress",
      },
      numbers: [
        {
          hy: "Ավարտված նախագծեր",
          ru: "Реализованные проекты",
          en: "Completed projects",
        },
        {
          hy: "Ընթացիկ նախագծեր",
          ru: "Текущие проекты",
          en: "Ongoing projects",
        },
        {
          hy: "Աշխատակիցներ",
          ru: "Сотрудники",
          en: "Our Staff",
        },
      ],
      services: {
        title: {
          hy: "Ծառայություններ",
          ru: "Услуги",
          en: "Services",
        },
        subtitle: {
          hy: "Ֆանարջյան կլինիկան դինամիկ զարգացող և մշտապես կատարելագործվող բժշկական կենտրոն է: Մեր աշխատանքի հիմնական նպատակն է պացիենտներին մատուցել որակյալ ծառայություններ և լավագույն սպասարկում:",
          ru: "Клиника Фанарджян активно развивающийся и постоянно совершенствующийся медицинский центр. Наша работа неизменно направлена на единую цель – предоставить своим пациентам качественные услуги и грамотное обслуживание.",
          en: "Main Services",
        },
      },
      mission: {
        title: {
          hy: "Ողջույնի խոսք",
          ru: "Приветственное слово",
          en: "Приветственное слово",
        },
        subtitle: {
          hy: "Ուրախ ենք ողջունելու Ձեզ մեր կայքում: Այստեղ Դուք կծանոթանաք Ֆանարջյան կլինիկայի գործունեությանը և անձնակազմին, կստանաք հիմնական տեղեկություններ հետազոտությունների և բուժման մասին:",
          ru: "Мы рады приветствовать Вас на нашем сайте. Здесь Вы ознакомитесь с основной деятельностью и персоналом Клиники Фанарджян, а также основными принципами диагностики и лечения.",
          en: "Main Services",
        },
      },
      aboutUs: {
        main: {
          hy: "Իմացեք մեր մասին ավելին",
          ru: "Узнайте о нас больше",
          en: "Learn more about us",
        },
        button: {
          hy: "Մանրամասն",
          ru: "Подробно",
          en: "More details",
        },
      },
      wchus: {
        title: {
          hy: "Ինչու՞ ընտրել մեզ։",
          ru: "Почему мы?",
          en: "Why work with us?",
        },
        subtitle: {
          hy: "Մենք նախագծում ենք այն, ինչ Դուք մտածում եք",
          ru: "Мы проектируем то, что вы думаете",
          en: "We will prototype your vision",
        },
        content: [
          {
            hy: "Մեր հաջողության գաղտնիքը ոլորտի լավագույն մասնագետներից բաղկացած մեր թիմն է։",
            ru: "Наши высококвалифицированные сотрудники - залог нашего успеха.",
            en: "Our highly qualified employees are the key of our success.",
          },
          {
            hy: "Մենք անհատական մոտեցում և ճկուն լուծումներ ենք առաջարկում մեր գործընկերներին։",
            ru: "Мы предлагаем нашим партнерам индивидуальный подход и гибкие решения.",
            en: "We offer individual approach and flexible solutions to our partners.",
          },
          {
            hy: "Ընկերությունում կարևորվում է առողջ, ստեղծագործ աշխատանքային մթնոլորտ, որը խթանում է աշխատակիցների մասնագիտական և անձնային աճը։",
            ru: "Мы ценим здоровую и творческую рабочую атмосферу в нашей компании, что способствует профессиональному и личностному росту наших сотрудников.",
            en: "We appreciate healthy and creative work environment in our Company thus promoting professional and personal growth of our employees.",
          },
          {
            hy: "Մեր անձնակազմի ամուր և միասնական մնալու գրավականը փոխադարձ նվիրվածությունն է:",
            ru: "Преданность- ключ к нашим достижениям.",
            en: "Commitment is the key of our achievements.",
          },
          {
            hy: "Մենք սիրում ենք մեր գործը, և մեզ համար բարի համբավը ավելի մեծ կապիտալ է, քան ֆինանսները:",
            ru: "Мы любим то, что делаем, и наша слава более важный ресурс для нас, чем финансы.",
            en: "We love what we do, and our fame is more important resource for us, than finance.",
          },
        ],
      },
      patients: {
        title: {
          hy: "Հոդվածներ",
          ru: "Статьи",
          en: "For patients",
        },
        subtitle: {
          hy: "Ֆանարջյան կլինիկայում ախտորոշումն և բուժումն իրականացվում է բարձր որակավուրում և մեծ փորձ ունեցող մասնագետների կողմից: ",
          ru: "В клинике Фанарджян диагностика и лечение осуществляются высококвалифицированными специалистами – врачами с большим опытом работы.",
          en: "For patients",
        },
      },
      cta: {
        hy: "Մանրամասն տեղեկությունների համար",
        ru: "Для подробной информации ",
        en: "For more information",
      },
      cta2: {
        hy: "Այցելության գրանցվելու համար",
        ru: "Для записи на прием",
        en: "For more information",
      },
      cta3: {
        en: "We are on social platforms",
        hy: "Մենք սոց. հարթակներում",
        ru: "Мы в соцсетях",
      },
      projects: {
        title: {
          hy: "Մեր Նախագծերը",
          ru: "Наши проекты",
          en: "Our Projects",
        },
        subtitle: {
          hy: "Ծառայություններ",
          ru: "Сервисы",
          en: "Services",
        },
      },
      team: {
        title: {
          hy: "Մեր բժիշկները",
          ru: "Наши врачи",
          en: "Our doctors",
        },
        subtitle: {
          hy: "Մենք հավաքել ենք ժամանակակից մոտեցումներով բժիշկների փորձառու թիմ, ովքեր իրենց աշխատանքում հիմնվում են միյան ապացուցողական բժշկության չափանիշներին: Մեր մասնագետները կիրականացնեն հետազոտություններ և անհրաժեշտության դեպքում կնշանակեն բուժում:",
          ru: "Мы собрали опытную команду врачей с современным подходом к здоровью, которые в своей работе опираются исключительно на стандарты доказательной медицины.Наши врачи проведут полное обследования и при необходимости назначат оптимальное лечение.",
          en: "Our doctors",
        },
      },
      bookADoctor: {
        ru: "Записаться к врачу",
        en: "Book a doctor",
        hy: "Գրանցվել բժշկի մոտ",
      },
      contacts: {
        title: {
          hy: "Կոնտակտներ",
          ru: "Контакты",
          en: "Our doctors",
        },
        subtitle: {
          hy: "Շնորհակալ ենք մեր կայք այցելելու համար և հույս ունենք, որ տրամադրված տեղեկություններն օգտակար կլինեն Ձեզ: Մաղթում ենք Ձեզ և Ձեր մտերիմներին քաջառողջություն և լավ տրամադրություն:",
          ru: "Благодарим Вас за посещение нашего сайта и выражаем надежду, что полученная информация будет полезна для Вас.Желаем Вам и Вашим близким крепкого здоровья и отличного настроения!",
          en: "Our doctors",
        },
      },
      videSlide: {
        title: {
          hy: "Տեսանյութեր",
          ru: "Видео",
          en: "Video Gallery",
        },
      },
      gallerySlide: {
        title: {
          hy: "Նկարներ",
          ru: "Фото",
          en: "Gallery",
        },
        subtitle: {
          hy: "Դիտել բոլոր նկարները",
          ru: "Просмотреть все фото",
          en: "View all phootos",
        },
      },
    },
    aboutUs: {
      history: {
        hy: "հիմնական",
        ru: "История",
        en: "History",
      },
      mission: {
        hy: "ողջույնի խոսք",
        ru: "Наша миссия",
        en: "Our mission",
      },
      license: {
        hy: "Լիցենզիաներ",
        ru: "Лицензии",
        en: "Licenses",
      },
    },
    months: {
      hy: [
        "ՀՈՒՆ",
        "ՓԵՏ",
        "ՄԱՐՏ",
        "ԱՊՐ",
        "ՄԱՅ",
        "ՀՈՒՆ",
        "ՀՈՒԼ",
        "ՕԳՍ",
        "ՍԵՊ",
        "ՀՈԿ",
        "ՆՈՅ",
        "ԴԵԿ",
      ],
      ru: [
        "ЯНВ",
        "ФЕВ",
        "МАР",
        "АПР",
        "МАЙ",
        "ИЮНЬ",
        "ИЮЛЬ",
        "АВГ",
        "СЕН",
        "ОКТ",
        "НОЯ",
        "ДЕК",
      ],
      en: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
    },
    footer: {
      social: {
        hy: "Մենք սոց. հարթակներում",
        ru: "Мы в соцсетях",
        en: "We are on social platforms",
      },
    },
    explore: {
      hy: "Մանրամասն",
      ru: "Подробнее",
      en: "Explore",
    },
  },
};

export default config;
