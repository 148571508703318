import React, { useState, useRef, useEffect } from "react";
import config from "../config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useGlobal } from "../helpers/globalContext";
import "react-lazy-load-image-component/src/effects/blur.css";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export function Video({ sourceFile, source2, source3, source4, source5 }) {
  let [screenWidth] = useState(window.innerWidth.toString());
  const [shouldUseImage, setShouldUseImage] = useState(false);
  let videoParentRef = useRef(null);
  let parseUrl = (url) => {
    return `${config.api.API_URL}/${url}`;
  };
  let source = parseUrl(sourceFile?.uploads[0]?.path);
  if (screenWidth >= 720) source = source2 ? parseUrl(source2) : source;
  if (screenWidth >= 1020) source = source3 ? parseUrl(source3) : source;
  if (screenWidth >= 1400) source = source4 ? parseUrl(source4) : source;
  if (screenWidth >= 1900) source = source5 ? parseUrl(source5) : source;

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  });

  return shouldUseImage ? (
    <img src={source} alt="Muted Video" />
  ) : (
    <div
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
      <video
        loop
        muted
        autoplay
        playsinline
        preload="metadata"
        class='video'
      >
      <source src="${source}" type="video/mp4" />
      </video>`,
      }}
    />
  );
}

export const Image = React.memo(
  ({
    imageFile,
    imageUrl,
    uploadFile,
    classname,
    destiny = "image",
    loader = false,
    thumbnail = false,
    responsive = false,
    height,
    width = "100%",
    lightGallery,
    cardImg,
    ref,
  }) => {
    const [loaded, setLoaded] = useState(loader);
    const { scrollTop } = useGlobal();

    let filtered;
    if (uploadFile) {
      filtered = uploadFile;
    } else {
      filtered = imageFile?.uploads?.find((elem) => {
        if (elem.destiny === destiny) return elem;
        return null;
      });
    }

    const defaultImg = "/assets/download.svg";
    const Placeholder = filtered?.pathPlaceholder
      ? config.api.API_URL + "/" + filtered?.pathPlaceholder
      : defaultImg;
    const thumb = filtered?.pathThumb
      ? config.api.API_URL + "/" + filtered?.pathThumb
      : defaultImg;
    const small = filtered?.pathBreak1
      ? config.api.API_URL + "/" + filtered?.pathBreak1
      : defaultImg;
    const medium = filtered?.pathBreak2
      ? config.api.API_URL + "/" + filtered?.pathBreak2
      : defaultImg;
    const large = filtered?.pathBreak3
      ? config.api.API_URL + "/" + filtered?.pathBreak3
      : defaultImg;
    const xlarge = filtered?.pathBreak4
      ? config.api.API_URL + "/" + filtered?.pathBreak4
      : defaultImg;

    if (imageUrl) {
      return (
        <LazyLoadImage
          src={imageUrl}
          alt={"thumbnail"}
          effect="blur"
          ref={ref}
          height={height}
          width={width}
          placeholderSrc={Placeholder}
          loading="lazy"
          className={`${classname ? classname : "illustration-img"}`}
          scrollPosition={scrollTop}
        />
      );
    } else if (thumbnail) {
      return (
        <LazyLoadImage
          src={thumb}
          alt={"thumbnail"}
          effect="blur"
          ref={ref}
          height={height}
          width={width}
          placeholderSrc={Placeholder}
          loading="lazy"
          className={`${classname ? classname : "illustration-img"}`}
          scrollPosition={scrollTop}
        />
      );
    } else if (responsive) {
      if (lightGallery) {
        return (
          <a
            data-srcset={`${small} 576w, ${medium} 768w, ${large} 1020w, ${xlarge} 1200w`}
            data-src={`${small} 576w, ${medium} 768w, ${large} 1020w, ${xlarge} 1200w`}
          >
            <LazyLoadImage
              srcSet={`${small} 576w, ${medium} 768w, ${large} 1020w, ${xlarge} 1200w`}
              alt={
                imageFile
                  ? imageFile.href
                    ? imageFile.href
                    : "responsive"
                  : "responsive"
              }
              effect="blur"
              placeholderSrc={Placeholder}
              ref={ref}
              height={height}
              width={width}
              loading="lazy"
              className={`${classname ? classname : "illustration-img"}`}
              scrollPosition={scrollTop}
            />
          </a>
        );
      } else {
        return (
          <LazyLoadImage
            srcSet={`${small} 576w, ${medium} 768w, ${large} 1020w, ${xlarge} 1200w`}
            alt={"responsive"}
            effect="blur"
            ref={ref}
            data-responsive={`${small} 576w, ${medium} 768w, ${large} 1020w, ${xlarge} 1200w`}
            height={height}
            width={width}
            placeholderSrc={Placeholder}
            loading="lazy"
            className={`${classname ? classname : "illustration-img"}`}
            scrollPosition={scrollTop}
          />
        );
      }
    } else {
      return (
        <LazyLoadImage
          src={`${
            filtered?.path
              ? config.api.API_URL + "/" + filtered?.path
              : defaultImg
          }`}
          alt={"default"}
          ref={ref}
          effect="blur"
          height={height}
          width={width}
          placeholderSrc={Placeholder}
          loading="lazy"
          className={`${classname ? classname : "illustration-img"}`}
          scrollPosition={scrollTop}
        />
      );
    }
  }
);
