import config from "../../config";
// language stuff
import { useGlobal } from "../../helpers/globalContext";

export function Navigation() {
  let { lang } = useGlobal();

  const navigationPrimary = [
    {
      name: config.lang.menu.home[lang],
      route: "home",
    },
    {
      name: config.lang.menu.aboutUs[lang],
      icon: "fas fa-address-card",
      route: "about-us",
    },
    {
      name: config.lang.menu.services[lang],
      route: "category",
    },
    {
      name: config.lang.menu.team[lang],
      route: "team",
    },
    {
      name: config.lang.menu.video[lang],
      route: "video",
    },
    {
      name: config.lang.menu.patients[lang],
      route: "patients",
    },
    {
      name: config.lang.menu.gallery[lang],
      route: "gallery",
    },
    {
      name: config.lang.menu.contacts[lang],
      route: "contacts",
    },
  ];

  return navigationPrimary;
}
