import React, { useState, useEffect } from "react";
import { Navigation } from "../header/listItems";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { Image } from "../../helpers/mediaComponent";
import { useGlobal } from "../../helpers/globalContext";

export default function Navbar(props) {
  const location = useLocation();
  let [open, setOpen] = useState(false);
  const { lang } = useGlobal();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let body = document.querySelector("body");
    if (open) {
      body?.classList.add("fixed-body");
    } else if (!open) {
      body?.classList.remove("fixed-body");
    }
  }, [open]);

  return (
    <div className="header-nav-bar">
      <div className="header-nav-bar-background"></div>
      <div className="row">
        <div className="desktop-nav nav col-12">
          <div className="row justify-content-between">
            <div className="logo">
              <Link to={"/"} rel="noopener noreferrer" className="temp-logo">
                <Image
                  imageUrl={"/logo2-white.png"}
                  responsive={true}
                  classname="logo-img"
                  alt="navbar"
                  width="200"
                  height="53"
                />
              </Link>
            </div>
            <div className="main-navigation-area">
              <div className="main-navigation" style={{ display: "block" }}>
                <nav>
                  <ul className="menu">
                    {Navigation().map((item, index) => {
                      if (item && item.name && item.route) {
                        return (
                          <li key={index}>
                            {/* {item.collapse ? (
														<>
															<div
																onClick={
																	toggleNavOpen
																}
																ref={
																	collapseElem
																}
															>
																<Link
																	className='nav-link'
																	ref={
																		collapseLink
																	}
																	to={''}
																>
																	{item.name
																		.charAt(
																			0
																		)
																		.toUpperCase() +
																		item.name.slice(
																			1
																		)}
																	<i className='right fas fa-angle-left'></i>
																</Link>
																<ul
																	className='nav-inner'
																	onClick={
																		toggleNavOpen
																	}
																>
																	{item.collapse.map(
																		(
																			innerItem,
																			innerIndex
																		) => {
																			return (
																				<li
																					className='nav-item'
																					key={
																						innerIndex
																					}
																				>
																					<Link
																						className={
																							activeRoute(
																								`/${innerItem.route}`
																							)
																								? 'nav-link active'
																								: 'nav-link'
																						}
																						to={`/${innerItem.route}`}
																					>
																						<p>
																							{innerItem.name
																								.charAt(
																									0
																								)
																								.toUpperCase() +
																								innerItem.name.slice(
																									1
																								)}
																						</p>
																					</Link>
																				</li>
																			);
																		}
																	)}
																</ul>
															</div>
														</>
													) : ( */}
                            <Link
                              className={
                                activeRoute(`${item?.route}`)
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              rel="noopener noreferrer"
                              to={`/${item?.route}/`}
                            >
                              {item?.name.charAt(0).toUpperCase() +
                                item?.name?.slice(1)}
                            </Link>
                            {/* )} */}
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-nav col-12">
          <div className="logo">
            <Link to={"/"} className="temp-logo">
              <Image
                imageUrl={"/logo2-white.png"}
                classname="logo-img"
                alt="navbar"
              />
            </Link>
          </div>
          <FontAwesomeIcon
            icon="bars"
            className="hamburger-icon"
            onClick={handleOpen}
          />
          <nav
            className={`mobile-nav-menu ${open ? "open" : ""}`}
            onClick={handleOpen}
          >
            <div className="mobile-nav-topbar">
              {/* <div className="logo">
                <Link to={"/"} className="temp-logo">
                  <Image
                    imageUrl={"/logo2-white.png"}
                    classname="logo-img"
                    alt="navbar"
                  />
                </Link>
              </div> */}
            </div>

            <FontAwesomeIcon
              icon="times"
              className="close-icon"
              onClick={handleOpen}
            />

            <ul className="mobile-nav-list">
              {Navigation().map((nav, index) => {
                if (nav && nav.name && nav.route) {
                  return (
                    <li key={index}>
                      <Link
                        className={activeRoute(`${nav.route}`) ? "active" : ""}
                        to={`/${nav.route}/`}
                      >
                        {nav.name.charAt(0).toUpperCase() + nav.name.slice(1)}
                      </Link>
                    </li>
                  );
                }
                return null;
              })}
            </ul>

            <div className="br"></div>

            <div className="mobile-nav-contacts">
              <ul className="contacts-list">
                {/* <div className="contacts-list-background"></div> */}
                <li className="contacts-list-item">
                  <a href={`tel:${config.contacts.tel[0]}`}>
                    <img src="/assets/i-phone.png" alt="phone" />{" "}
                    {config.contacts.tel[0]}
                  </a>
                </li>
                <li className="contacts-list-item">
                  <a href={`tel:${config.contacts.tel[1]}`}>
                    <img src="/assets/i-mobile.png" alt="mobile" />{" "}
                    {config.contacts.tel[1]}
                  </a>
                </li>
                <li className="contacts-list-item">
                  <a href={`tel:${config.contacts.tel[2]}`}>
                    <img src="/assets/i-mobile.png" alt="mobile" />{" "}
                    {config.contacts.tel[2]}
                  </a>
                </li>
                <li className="contacts-list-item">
                  <a href={`mailto:${config.contacts.email}`}>
                    <img src="/assets/i-email.png" alt="email" />
                    {config.contacts.email}
                  </a>
                </li>
                <li className="contacts-list-item">
                  <span>
                    <img src="/assets/i-location.png" alt="location" />
                    {config.contacts.address[lang]}
                  </span>
                </li>
              </ul>
            </div>
          </nav>
          <div
            className={`mobile-nav-overlay ${open ? "open" : ""}`}
            onClick={handleOpen}
          ></div>
        </div>
      </div>
    </div>
  );
}
