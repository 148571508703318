import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { useGlobal } from "../../helpers/globalContext";
import * as API from "../../helpers/api";
import Loading from "../../helpers/loading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Footer() {
  const [isBusy, setBusy] = useState(true);
  const [categories, setcategories] = useState("");
  let { lang } = useGlobal();

  useEffect(() => {
    (async () => {
      let rowData = await API.get(
        config.api.category,
        { temprorary: { $ne: true } },
        { index: 1 },
        true
      );
      setcategories(rowData);
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <div className="footer-wrap">
        <div className="footer">
          <div className="container row">
            <div className="col-xs-12 col-md-4 main-footer-box">
              <h2 className="title">{config.lang.menu.contacts[lang]}</h2>
              <div className="footer-contacts">
                <ul className="footer-contacts">
                  <li className="footer-contacts-list-item">
                    <a
                      aria-label="tel"
                      href={`tel:${config.contacts.tel[0]}`}
                      rel="noreferrer"
                    >
                      {config.contacts.tel[0]}
                    </a>
                  </li>
                  <li className="footer-contacts-list-item">
                    <a
                      aria-label="tel"
                      href={`tel:${config.contacts.tel[1]}`}
                      rel="noreferrer"
                    >
                      {config.contacts.tel[1]}
                    </a>
                  </li>
                  <li className="footer-contacts-list-item">
                    <a
                      aria-label="tel"
                      href={`tel:${config.contacts.tel[2]}`}
                      rel="noreferrer"
                    >
                      {config.contacts.tel[2]}
                    </a>
                  </li>
                  <li className="footer-contacts-list-item">
                    <a
                      aria-label="maps"
                      href={`https://maps.yandex.com/?ll=44.508394,40.193115&z=19`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {config.contacts.address[lang]}
                    </a>
                  </li>
                  <li className="footer-contacts-list-item">
                    <a
                      aria-label="email"
                      href={`mailto:${config.contacts.email}`}
                      rel="noreferrer"
                    >
                      {config.contacts.email}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 main-footer-box">
              <h3 className="title">{config.lang.footer.social[lang]}</h3>
              <ul className="footer-social social-links-icons">
                <li>
                  <a
                    href={`${config.contacts.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="facebook"
                    className="social-link facebook"
                  >
                    <img
                      src="/assets/facebook.png"
                      alt="facebook"
                      width={40}
                      height={40}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.contacts.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="instagram"
                    className="social-link instagram"
                  >
                    <img
                      src="/assets/instagram.png"
                      alt="instagram"
                      width={40}
                      height={40}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`${config.contacts.youtube}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="youtube"
                    className="social-link youtube"
                  >
                    <img
                      src="/assets/youtube.png"
                      alt="youtube"
                      width={40}
                      height={40}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-4 main-footer-box">
              <h3 className="title">{config.lang.menu.categories[lang]}</h3>
              <div className="footer-services">
                <ul>
                  {categories?.map((item, key) => (
                    <li className="footer-services-list-item" key={key}>
                      <a href={`/category/${item._id}`} rel="noreferrer">
                        {lang === "en"
                          ? item.titleEn
                          : lang === "ru"
                          ? item.titleRu
                          : lang === "hy"
                          ? item.titleHy
                          : null}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="width-100">
              <div className="footer-links">
                <Link rel="noopener noreferrer" to="/terms/">
                  {config.lang.menu.terms[lang]}
                </Link>
                <Link rel="noopener noreferrer" to="/privacy/">
                  {config.lang.menu.privacy[lang]}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-wrap">
          <div className="copyright">
            © Copyright Fanarjyan {new Date().getFullYear()}. Designed and
            Developed by
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://deepmindsystems.com"
            >
              Deepmindsystems.com
            </a>
          </div>
        </div>
      </div>
    );
}

export default Footer;
