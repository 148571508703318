import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config.js";

// language stuff
import { useGlobal } from "../../helpers/globalContext";

export default function Topbar() {
  // language stuff
  let { lang, setLang } = useGlobal();

  const changeLanguage = (e) => {
    if (e.target.lang === "ru") {
      document.documentElement.lang = "ru";
      setLang("ru");
    } else if (e.target.lang === "hy") {
      document.documentElement.lang = "hy";
      setLang("hy");
    } else if (e.target.lang === "en") {
      document.documentElement.lang = "en";
      setLang("en");
    }
  };

  return (
    <div className="header-top-bar">
      <div className="col-sm-12 col-lg-8 header-contact justify-content-center padding-0">
        <ul>
          <li>
            <a href={`tel:${config.contacts.tel[0]}`} rel="noopener noreferrer">
              <FontAwesomeIcon icon="phone-alt" />
              {config.contacts.tel[0]}
            </a>
          </li>
          <li>
            <a href={`tel:${config.contacts.tel[2]}`} rel="noopener noreferrer">
              <FontAwesomeIcon icon="phone-alt" />
              {config.contacts.tel[2]}
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon="calendar" />
            {`${config.lang.topbar.workingDays[lang]}`}
          </li>
          <li>
            <FontAwesomeIcon icon="clock" />
            {`${config.lang.topbar.workingHours}`}
          </li>
          <li>
            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            {`${config.contacts.address[lang]}`}
          </li>
        </ul>
      </div>
      <div className="col-sm-12 col-lg-4 header-social justify-content-center padding-0">
        <ul className="language-selector-list">
          <li
            className={
              lang === "hy"
                ? "selected language-selector-list-item"
                : "language-selector-list-item"
            }
            onClick={changeLanguage}
            lang="hy"
          >
            Հայ
          </li>
          <li
            className={
              lang === "ru"
                ? "selected language-selector-list-item"
                : "language-selector-list-item"
            }
            onClick={changeLanguage}
            lang="ru"
          >
            Рус
          </li>
        </ul>
        <span className="divider"></span>
        <ul className="social-links-icons">
          <li>
            <a
              href={`${config.contacts.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
              className="social-link facebook"
            >
              <img
                src="/assets/facebook.png"
                alt="facebook"
                width={30}
                height={30}
              />
            </a>
          </li>
          <li>
            <a
              href={`${config.contacts.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
              className="social-link instagram"
            >
              <img
                src="/assets/instagram.png"
                alt="instagram"
                width={30}
                height={30}
              />
            </a>
          </li>
          <li>
            <a
              href={`${config.contacts.youtube}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="youtube"
              className="social-link youtube"
            >
              <img
                src="/assets/youtube.png"
                alt="youtube"
                width={30}
                height={30}
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
